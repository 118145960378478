.#{$ui}-accordion {
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
    $accordion: &;

    &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--type2 {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;

        #{$accordion} {
            &__head, &__body {
                padding: 0;
            }

            &__head {
                border-bottom: none;
                
                &__title {
                    font-size: 13px;
                    font-weight: 900;
                }
            }

            &__body {
                margin-top: 10px;

            }

            @include media-breakpoint-down(md) {
                &__head {
                    position: relative;
                    padding: 15px 0;
                    
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: -20px;
                        width: calc(100% + 40px);
                        background-color: $color-white1;
                        height: 1px;
                        display: flex;
                    }
                }
            }
        }


    }

    &__head {
        padding: 30px;
        border-bottom: 1px solid $color-white1;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &__toggle-icon {
            .fa-minus {
                display: none;
            }
        }
        
        &__title {
            font-size: 16px;
            font-weight: 700;
            color: $color-gray4;
        }
    }

    &__body {
        padding: 0 30px 30px 30px;
        line-height: 1;
        font-size: 14px;
        color: $color-gray3;
        display: none;
    }

    &--rotate-icon {
        #{$accordion} {
            &__head {
                &__toggle-icon {
                    transition: all .3s;
                }
            }
        }
    }

    &--active {
        #{$accordion} {
            &__head {
                border-bottom: none;

                &__toggle-icon {
                    .fa-plus {
                        display: none;
                    }
                    
                    .fa-minus {
                        display: inline-block;
                    }
                }
            }

            &__body {
                display: block;
            }
        }
    }

    &--active#{$accordion}--rotate-icon {
        #{$accordion} {
            &__head {
                &__toggle-icon {
                    transform: rotate(180deg);
                }
            }
        }
        
    }
}

.ant-collapse-item{
    background-color: #fff;
    border-radius: 0px;
    box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
    $accordion: &;

    &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--type2 {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;

        #{$accordion} {
            &__head, &__body {
                padding: 0;
            }

            &__head {
                border-bottom: none;
                
                &__title {
                    font-size: 13px;
                    font-weight: 900;
                }
            }

            &__body {
                margin-top: 10px;

            }

            @include media-breakpoint-down(lg) {
                &__head {
                    padding: 15px 0;
                    
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: -20px;
                        width: calc(100% + 40px);
                        background-color: $color-white1;
                        height: 1px;
                        display: flex;
                    }
                }
            }
        }


    }

    &__head {
        padding: 30px;
        border-bottom: 1px solid $color-white1;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &__toggle-icon {
            .fa-minus {
                display: none;
            }
        }
        
        &__title {
            font-size: 16px;
            font-weight: 700;
            color: $color-gray4;
        }
    }

    &__body {
        padding: 0 30px 30px 30px;
        line-height: 1;
        font-size: 14px;
        color: $color-gray3;
        display: none;
    }

    &--rotate-icon {
        #{$accordion} {
            &__head {
                &__toggle-icon {
                    transition: all .3s;
                }
            }
        }
    }

    &--active {
        #{$accordion} {
            &__head {
                border-bottom: none;

                &__toggle-icon {
                    .fa-plus {
                        display: none;
                    }
                    
                    .fa-minus {
                        display: inline-block;
                    }
                }
            }

            &__body {
                display: block;
            }
        }
    }

    &--active#{$accordion}--rotate-icon {
        #{$accordion} {
            &__head {
                &__toggle-icon {
                    transform: rotate(180deg);
                }
            }
        }
        
    }
}

.ant-collapse{
    width: 100%;
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
    color: #4d4e4e;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 700;
    color: #4d4e4e;
    padding: 30px;
}