/*
 *
 * VARIABLES
 *
 */

// Colors
$color-black1: #252525;
$color-black2: #2e2e2e;
$color-black3: #323232;
$color-white1: #eeeeee;
$color-white2: #f8f8f8;
$color-white3: #ffffff;
$color-white4: #f7f7f7;
$color-gray1: #3e4245;
$color-gray2: #cccccc;
$color-gray3: #848689;
$color-gray4: #4d4e4e;
$color-gray5: #383f47;
$color-gray6: #333d47;
$color-gray7: #3e4246;
$color-gray8: #3e4246;
$color-gray9: #d8d8d8;
$color-gray10: #6f6f6f;
$color-gray11: #585858;
$color-gray12: #3b3b3b;
$color-gray13: #979797;
$color-gray14: #a8a8a8;
$color-gray15: #e6e4e4;
$color-green2: #6ec80b;
$color-green1: #7ed321;
$color-red1: #f23737;
$color-red2: #ff4a4a;
$color-yellow1: #fed12f;
$color-yellow2: #ffcc05;
$color-yellow3: #FFD635;
$yellow2-filter: invert(81%) sepia(72%) saturate(2741%) hue-rotate(353deg) brightness(103%) contrast(102%);

// App Variables
$ui: 'im';
$body-bg-color: $color-white1;
$primary-text-color: $color-gray4;
$section-heading-color: $color-gray4;
$section-description-color: $color-black1;
