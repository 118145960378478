.#{$ui}-header  {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1030;
    $header: &;

    .header-row {
        position: relative;
        padding: 5px 0;
        display: flex;
        align-items: center;
        min-height: 67px;

        &.header-row--bigger {
            min-height: 68px;
        }

        &.header-row--bg-gray {
             background-color: $color-gray3;
        }
        &.header-row--bg-white {
             background-color: #fff;
        }

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $color-gray9;
            opacity: .26;
            position: absolute;
            top: 100%;
            left: 0;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }

    .header-nav-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    nav {
        &.header-nav {
            min-height: 45px;
            height: 100%;
            display: inline-flex;
            align-items: center;

            .header-nav__link {
                color: #fff;
                margin-right: 25px;
                font-size: 12px;
                font-weight: 500;
                display: inline-flex;
                align-items: center;
                transition: all .3s;

                i {
                    font-size: 14px;
                }

                &:hover, &:focus, &:active, &:visited {
                    color: #fff;
                    opacity: 1;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &.header-nav__link--has-border {
                    padding: 7px 11px;
                    border-radius: 14px;
                    border: 1px solid #fff;

                    &:hover, &:focus, &:active {
                        color: $color-black1;
                        background-color: #fff;
                        opacity: 1;
                    }
                }

                &.header-nav__link--has-separator {
                    margin-right: 0;

                    &:after {
                        content: "";
                        display: inline-flex;
                        width: 1px;
                        height: 36px;
                        background-color: #fff;
                        opacity: .26;
                        margin: 0 30px;
                    }
                }
            }
        }
    }

    .header-nav-separator {
        display: inline-flex;
        width: 1px;
        height: 36px;
        background-color: #fff;
        opacity: .26;
        margin: 0 30px;
    }

    .logo-black {
        display: none;
    }

    .logo-white {
        display: block;
    }

    .separator {
        display: inline-flex;
        width: 1px;
        height: 56px;
        background-color: #fff;
        opacity: .26;
        margin: 0 20px;
    }

    .header-mobile {
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 55px;
        background-color: #fff;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1010;

        .logo {
            &--mobile {
                height: auto;
                width: auto;

                img {
                    max-width: 130px;
                    height: auto;
                }
            }
        }

        &__menu-button {
            min-height: auto;
            padding: 5px 9px;
            background-color: transparent;
            border: 1px solid $color-gray4;
            border-radius: 0;
            font-size: 13px;
            font-weight: 500;
            margin-left: auto;
        }
    }

    .header-mobile-menu {
        position: fixed;
        left: 0;
        top: 55px;
        width: 100%;
        height: calc(100vh - 55px);
        background-color: #fff;
        z-index: 1020;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        animation-name: mobileMenuActive;
        animation-duration: .3s;

        &--active {
            display: flex;

        }

        @keyframes mobileMenuActive {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        button {
            background: none;
            border: none;
        }

        &__head {
            padding: 16px 30px;
            display: flex;
            background-color: $color-white2;
            border: 1px solid $color-gray2;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;

            &--not-log-in {
                background-color: $color-yellow2;
                border-color: transparent;
                min-height: 65px;
                padding: 0 10px;
            }

            button {
                font-size: 12px;
                color: $color-gray10;
            }
        }

        // &__body {
        //     padding-left: 30px;
        //     padding-right: 30px;
        // }

        &__body {
            flex-grow: 1;
            padding-top: 20px;
            padding-bottom: 81px;
            overflow-y: auto;


            @supports (-webkit-overflow-scrolling: touch) {
                padding-bottom: 160px;
            }
        }

        &__bottom {
            display: flex;
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 61px;
            background-color: $color-white2;
            align-items: stretch;

            &__left, &__right {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__left {
                background-color: $color-yellow2;
                color: $color-black1;

                a {
                    color: inherit;

                    &:hover, &:focus, &:active {
                        color: inherit;
                    }
                }
            }

            &__right {
                background-color: $color-gray4;
                color: #fff;

                select {
                    color: inherit;
                    position: relative;
                    left: -5px;
                    font-size: 16px;
                }
            }
        }

        &__mobile-nav {
            display: flex;
            flex-direction: column;

            > * {
                padding-left: 30px;
                padding-right: 30px;
            }

            .#{$ui}-accordion {
                padding: 0;
            }

            .#{$ui}-accordion__head {
                padding: 0 30px;
                border-bottom: none;
            }

            .#{$ui}-accordion__body {
                background-color: $color-white2;
                padding: 0;
                position: relative;
                top: -15px;
            }

            > a, .#{$ui}-accordion__head__title {
                font-size: 18px;
                color: $color-gray4;
                padding-bottom: 0;

                &:hover, &:active, &:focus {
                    color: $color-gray4;
                }

                &:not(:last-of-type) {
                    padding-bottom: 30px;
                }
            }
        }


    }

    &--sticky {
         box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
         position: sticky;
         bottom: 100vh;
    }

    &--fixed {
         position: fixed;
         position: -webkit-fixed;
         top: 0;
         box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);

    }

     &--backgrounds, &--sticky {
        .logo {
            height: 45px;

            .logo-black {
                display: block;
            }

            .logo-white {
                display: none;
            }
        }

        .header-row-top {
                background-color: $color-gray3;
                transition: all .4s;
        }

        .header-row-bottom {
            background-color: #fff;
            color: $color-gray4;
            transition: all .6s;

            .#{$ui}-dropdown {

                &__button {
                    color: inherit;
                    border-color: transparent !important;

                    &__arrow {

                        i {

                            color: inherit;
                        }
                    }
                }

                .dropdown-menu {
                    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
                }
            }


            .#{$ui}-button {
                color: inherit;

                i {
                    color: inherit;
                }
            }

            .#{$ui}-header__action-button {
                border: 2px solid $color-gray4;

                &:hover {
                    background-color: $color-gray4;
                    color: #fff;
                }

                &:hover {
                    background-color: $color-gray4;
                    color: $color-yellow2;
                }
            }

            .separator {
                background-color: $color-white1;
            }
        }
    }
}

.#{$ui}-header {

    &--padding-17 {
        padding: 17px 0;
    }

    &--padding-8 {
        padding: 8px 0;
    }

    &--bg-white {
        background-color: #fff;
    }
}

