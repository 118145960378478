.tooltip {
    &.show {
        opacity: 1;
    }

    &.bs-tooltip-right {
        .arrow {
            &:before {
                border-right-color: $color-white2;
            }
        }
    }

    &.bs-tooltip-left {
        .arrow {
            &:before {
                border-left-color: $color-white2;
            }
        }
    }

    &.bs-tooltip-top {
        .arrow {
            &:before {
                border-top-color: $color-white2;
            }
        }
    }

    &.bs-tooltip-bottom {
        .arrow {
            &:before {
                border-bottom-color: $color-white2;
            }
        }
    }

    .tooltip-inner {
        max-width: initial;
        background: $color-white2;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 0 25px 0 rgba(111, 111, 111, 0.4);
    }
}

.tooltipTransform {
    position: absolute;
    box-shadow: 0 0 25px 0 rgba(111, 111, 111, 0.4);
    padding: 0;

    &::after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: white;
        border-width: 10px;
        margin-top: -10px;
    }
}

.#{$ui}-help-icon-with-tooltip {
    display: inline-block;
    position: relative;
    font-size: 13px;

    &__icon {
        cursor: pointer;
    }

    &__content {
        position: absolute;
        padding: 8px 16px;
        background-color: white;
        border-radius: 4px;
        font-size: 13px;
        box-shadow: 0 0 25px 0 rgba(111, 111, 111, 0.4);

        visibility: hidden;
        opacity: 0;
        transition: opacity ease 200ms;

        &::after {
            content: "";
            position: absolute;
            border: 10px solid transparent;
        }

        &--right {
            left: 100%;
            transform: translateX(15px) translateY(calc(-50% - 8px));

            &::after {
                right: 100%;
                top: 50%;
                margin-top: -10px;
                border-right-color: white;
            }
        }

        &--left {
            right: 100%;
            transform: translateX(-15px) translateY(calc(-50% - 8px));

            &::after {
                left: 100%;
                top: 50%;
                margin-top: -10px;
                border-left-color: white;
            }
        }

        &--bottom {
            top: 100%;
            transform: translateX(calc(-50% + 7px)) translateY(15px);

            &::after {
                bottom: 100%;
                left: 50%;
                margin-left: -10px;
                border-bottom-color: white;
            }
        }

        &--top {
            bottom: 100%;
            transform: translateX(calc(-50% + 7px)) translateY(-16px);

            &::after {
                top: 100%;
                left: 50%;
                margin-left: -10px;
                border-top-color: white;
            }
        }
    }

    &:hover > &__content,
    &:focus > &__content,
    &:active > &__content {
        visibility: visible;
        opacity: 1;
    }
}
