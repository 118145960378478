.#{$ui}-step-bar {
    width: 100%;
    display: flex;
    min-height: 210px;
    align-items: flex-end;
    position: relative;
    counter-reset: stepcount;
    $stepBar: &;

    &__step {
        flex-grow: 1;
        position: relative;
        $stepBarStep: &;
    
        &__bar {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 27px;
            position: relative;
            

            &__inner {
                position: relative;
                width: 100%;
                overflow: hidden;
            }

            &__track, &__progress {
                display: block;
                height: 5px;
            }

            &__track {
                background-color: $color-gray2;
                width: 100%;
            }

            &__progress {
                animation-name: progressBar;
                animation-iteration-count: 1;
                background-color: $color-yellow2;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }

            @keyframes progressBar {
                0% {
                  width: 0%;
                }
                
                100% {
                  width: 100%;
                }
              }
    
            &__point {
                position: absolute;
                content: "";
                left: 50%;
                bottom: 3px;
                width: 30px;
                height: 30px;
                border: 5px solid $color-yellow2;
                border-radius: 50px;
                transform: translate(-50%, 50%);
                background-color: $color-white2;
            }

            &__indicator {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                height: 130px;
                background-color: #fff;
                margin-bottom: 50px;
                border-radius: 5px;
                box-shadow: 0 0 30px 0 rgba(207, 207, 207, 0.66);
                position: relative;

                &--bigger {
                    width: 160px;
                    height: 173px;

                    svg, img {
                        width: 80%;
                    }
                }

                img, svg {
                    width: 60%;
                }
        
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%);
                    z-index: 1;
                    border: 15px solid transparent;
                    border-top-color: #fff;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__heading {
                font-weight: 900;
                font-size: 24px;
                color: $color-black1;
                margin-bottom: 5px;
            }
        
            &__description {
                font-size: 18px;
                font-weight: 300;
                color: $color-black1;
            }
        }

        &--active {
            
            #{$stepBarStep}__bar {
                border-color: $color-yellow2;   

                &:after {
                    border-color: $color-yellow2;
                }

                &__progress {
                    width: 100%;
                }

                &__indicator {
                    background-color: $color-yellow2;

                    &:after {
                        border-top-color: $color-yellow2;
                    }
                }
            }

            // After step
            & ~ #{$stepBarStep} {
                
                #{$stepBarStep}__content__heading {
                    color: $color-gray10;
                }

                #{$stepBarStep}__content__description {
                    color: $color-gray10;
                }

                #{$stepBarStep}__bar {
                    border-color: $color-white1;

                    &__point {
                         border-color: $color-gray2;
                    }

                    &__progress {
                        width: 0;
                    }

                    &__indicator { 
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &--numbers {
        #{$stepBar}__step__content__heading {
            &:before {
                counter-increment: stepcount;
                content: counter(stepcount)".";
            }
        }
    }
}

.#{$ui}-step-bar-mobile {
    display: flex;
    flex-direction: column;

    &__step {
        display: flex;
        width: 100%;
        max-width: 290px;
        height: auto;
        padding: 25px 10px;
        border-radius: 4px;
        box-shadow: 0 0 30px 0 rgba(207, 207, 207, 0.66);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-color: #fff;

        &__indicator {
            margin-bottom: 30px;

            svg {
                width: 80px;
                height: 80px;
            }
        }

        &__title, &__description {
            color: $color-black1;
            text-align: center;
        }

        &__title {
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: 900;
        }

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &--active {
            background-color: $color-yellow2;
        }

    }
}

.#{$ui}-step-bar2 {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    counter-reset: stepcount;
    $stepBar2: &;

    &__item {
        display: inline-flex;
        padding: 19px 15px;
        margin-bottom: 2px;
        background-color: #fff;
        font-size: 14px;
        color: $color-gray3;
        font-weight: 400;
        align-items: center;
        position: relative;

        &__text {
            display: inline-flex;
            align-items: center;
        }

        &--inprogress {
             &:before {
                content: "";
                display: block;
                width: 5px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $color-yellow2;
             }

            font-weight: 900;
            color: $color-gray4;
        }

        &--completed {
            color: $color-gray4;
            font-weight: 500;

            #{$stepBar2}__item {
                &__text {
                    &:before {
                        font-family: 'Font Awesome 5 Pro';
                        content: "\f00c" !important;
                        color: #fff;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 1px solid $color-green2 !important;
                        background-color: $color-green2;
                        font-weight: 700;
                        font-size: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }
                }
           
            }
        }
    }

    &--numbers {
        #{$stepBar2}__item {

            &__text {
                 &:before {
                counter-increment: stepcount;
                content: counter(stepcount);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $color-gray13;
                font-weight: 700;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }
            }
           
        }
    }
}

.#{$ui}-step-bar3 {
    display: flex;
    flex-direction: column;
    counter-reset: stepcount3;
    $stepBar3: &;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        &__title, &description {
            font-size: 14px;
            color: $color-gray4;
        }

        &__indicator {
            margin-bottom: 15px;
        }

        &__title {
            font-weight: 900;
            margin-bottom: 5px;
        }

        &__description {
            margin-top: 5px;
            text-align: center;
        }
    }
}