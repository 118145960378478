
.flatpickr-day {
    &.selected {
        background-color: $color-yellow2;
        border-color: $color-yellow2;

        &:hover {
            background-color: $color-yellow2;
            border-color: $color-yellow2;
        }
    }

    &.today {
        border-color: $color-yellow2;
        border-width: 2px;
    }
}