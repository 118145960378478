html,body, .body {
    height: 100vh;
}

body, .body {
    display: flex;
    flex-direction: column;
}

.p-relative {
    position: relative;
}

.row {
    &--mb30 {
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

}

.mb-30-items {
    & > * {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.lock {
    overflow: hidden;

    .#{$ui}-header {
        position: fixed;
        height: 100%;
    }
}

// paddings 
.pl-sm-30 {
    @include media-breakpoint-up(sm) {
        padding-left: 30px;
    }
}

// margins
.mt-60 {
    margin-top: 60px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.has-header {
    padding-top: 135px;

    @include media-breakpoint-down(md) {
        padding-top: 55px;
    }

    &--small {
        padding-top: 71px;
    }

    main {
        margin-bottom: 120px;
    }
}

.p-20 {
    padding: 20px;
}

.p-40 {
    padding: 40px;
}

.px-1p5 {
    padding-left: 0.35rem;
    padding-right: 0.35rem;
}

.mw-115 {
    max-width: 115px;
}

.mw-60 {
    max-width: 60px;
}

.mw-700 {
    max-width: 700px;
}

.h-240 {
    height: 240px;
}

.h-auto {
     height: auto !important;
}

.h-lg-100 {
     @include media-breakpoint-up(lg) {
       height: 100% !important;
    }
}

.sticky {
        position: sticky;
        position: -webkit-sticky; 

        z-index: 1010;
        top: 0;

        @include media-breakpoint-down(md) {
            top: 55px;
        }
    }

.mh-container {
    min-height: 55vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.stopScrolling {
    overflow: hidden;
}

.register-skip-button{
    color: #4d4e4e; 
    font-size: 16px;
    background-color: #ffcc05;
    padding: 7px 20px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.loading-container{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2000;
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.im-left-menu{
    position: sticky;
    top: 145px;
}