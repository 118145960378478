.#{$ui}-image-gallery, .#{$ui}-image-gallery-extended {
    &__nav, &__view {

        &__slider {
             &__item {
                background-color: #fff;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                justify-content: center;
            }
        }
       
    }
}

a[data-ig-to-nav] {
    transition: color .3s;
    &:hover {
        color: $color-yellow2 !important;
    }
}

.#{$ui}-image-gallery {
    
    &__nav {
        margin-top: 15px;

        &__item {
            width: 100%;
            height: 94px;
            display: flex;
            background-color: #fff;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            opacity: .7;
            transition: .3s opacity;
            position: relative;

            &:hover, &:active, &:focus {
                opacity: 1;
            }

            &--overlay {
                opacity: 1;
            }

            &__overlay {
                background-color: rgba(62, 66, 69, 0.68);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                font-weight: 700;
                color: $color-yellow2;
            }
        }
    }
}

.#{$ui}-image-gallery-extended {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.96);
    width: 100vw;
    height: 100vh;
    z-index: 1030;
    padding-top: 50px;
    display: none;

    &__view, &__nav {
        &__slider {
            &__item {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__view {
        &__slider {
            &__item {
                height: 50vh;
                img {
                    object-fit: contain;
                }
            }
        }
    }

    &__nav {
        margin-top: 15px;

        &__slider {
            overflow-x: hidden;

            .slick-list {
                margin-left: -10px;
            }

            &__item {
                background: transparent;
                height: 84px;
                margin-left: 10px;
                cursor: pointer;
                opacity: .5;
                transition: .3s opacity;

                &:focus {
                    outline: none;
                }

                &:hover, &:active, &:focus {
                    opacity: 1;
                }
            }
        }
    }

    &__close {
        position: fixed;
        right: 40px;
        top: 40px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(sm) {
            right: 20px;
            top: 20px;
        }

        i {
            font-size: 25px;
            margin-bottom: 5px;
        }
    }

    &--active {
        display: block;
        animation-name: imageGalleryExtendedActive;
        animation-duration: .6s;
    }

    @keyframes imageGalleryExtendedActive {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}