/*
 *
 * TYPO
 *
 */

 html {
     font-size: 16px;
 }

 body, .body {
     font-family: Roboto, sans-serif;
     font-size: 1em;
     font-weight: 400;
     color: $primary-text-color;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
 }

 em {
     font-style: normal;
     font-weight: 500;
 }

 blockquote {
     margin: 0;
 }

 p {
     line-height: 1.4;
 }

 a {
     text-decoration: none;
     font-size: 1em;
     color: $color-gray10;
     font-weight: 900;

     &:hover, &:focus, &:active, &:visited {
         text-decoration: none;
         color: $color-gray10;
     }
 }

 // Colors
 .lighter {
    color: $color-gray10;
}

.text-black1 {
    color: $color-black1 !important;
}

 .text-gray2 {
     color: $color-gray2 !important;
 }

 .text-gray3 {
     color: $color-gray3 !important;
 }

 .text-gray4 {
     color: $color-gray4 !important;
 }

 .text-gray7 {
     color: $color-gray7 !important;
 }

 .text-gray10 {
     color: $color-gray10 !important;
 }

 .text-gray12 {
     color: $color-gray12 !important;
 }

 .text-white {
     color: #fff;
 }

 .text-yellow2 {
     color: $color-yellow2 !important;
 }

 .text-red1 {
     color: $color-red1;
 }

 .text-red2 {
     color: $color-red2;
 }

 .text-bg-black {
     background-color: $color-black1;
 }

 .text-green2 {
     color: $color-green2;
 }

 .text-gray14 {
     color: $color-gray14;
 }

// Sizes
.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-lg-18 {
    @include media-breakpoint-up(lg) {
       font-size: 18px !important;
    }
}


.fs-20 {
    font-size: 20px !important;
}

.fs-21 {
    font-size: 21px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-55 {
    font-size: 55px !important;
}

.fs-44 {
    font-size: 44px !important;
}

.fs-60 {
    font-size: 60px !important;
}

.fs-70 {
    font-size: 70px !important;
}

.fs-90 {
    font-size: 90px !important;
}

 // Weights
 .fw-100 {
     font-weight: 100 !important;
 }

 .fw-300 {
     font-weight: 300 !important;
 }

 .fw-400 {
     font-weight: 400 !important;
 }

 .fw-500 {
     font-weight: 500 !important;
 }

 .fw-700 {
    font-weight: 700 !important;
 }

 .fw-900 {
    font-weight: 900 !important;
 }

 // Headings
 h1, h2, h3, h4 {
     margin: 0;
 }

 .h1 {
     font-size: 44px;
     font-weight: 900;
 }

.h2, .h3 {
    color: $color-gray4;
    margin-bottom: 12px;

    &--lighter {
        color: $color-gray10;
    }
}

.h2 {
    font-size: 30px;
    font-weight: 900;
}

.h3 {
    font-size: 25px;
    font-weight: 900;
}

.h5 {
    font-size: 20px;
    font-weight: 900;
}

.error-container {
    .parsley-errors-list {
        &.filled {
            padding-top: 6px;
            color: $color-red1;
        }
    }
}

// opacity
.op-03 {
    opacity: .3;
}

// line height
.lh-1p4 {
    line-height: 1.4;
}

a {
    &.link-hover-white {
        transition: all .3s;
         &:hover, &:focus, &:active {
                color: #fff;
            }
    }

    &.link-hover-black {
        transition: all .3s;
         &:hover, &:focus, &:active {
                color: #000;
            }
    }
}

.hover-to-yellow {
    transition: color .3s;
    &:hover {
        color: $color-yellow2;
    }
}