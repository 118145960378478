.#{$ui}-radiobox {
    position: relative;
    width: 100%;
    display: flex;
    $radiobox: &;

    &--inline {
        display: inline-flex;
        width: auto;
    }

    input[type="radio"] {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;

        &:checked {
            & + #{$radiobox}__check {

                i, &:before {
                    opacity: 1;
                }
            }

            & + #{$radiobox}__check + #{$radiobox}__text  {
                color: $color-gray4;
                font-weight: 500;
            }
        }
    }

    label {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &__text {
        display: inline-flex;
        font-size: 14px;
        color: $color-gray3;
        font-weight: 400;
    }

    &__check {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border: 2px solid $color-gray3;
        border-radius: 50%;
        display: inline-flex;
        margin-right: 9px;
        justify-content: center;
        align-items: center;
        transition: background-color .3s;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            height: 80%;
            background-color: $color-yellow2;
            z-index: 1;
            border-radius: 50%;
            opacity: 0;
            transition: opacity .3;
        }

        i {
            opacity: 0;
            color: #fff;
            font-size: 12px;
            transition: opacity .3s;
        }
    }

    &--type3 {
        #{$radiobox} {
            &__check {
                border-color: $color-gray2;
            }

            &__text {
               color: $color-gray4;
            }
        }
    }

    &--error {
        #{$radiobox} {
                &__check {
                    border-color: $color-red1;
                }
            }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.ant-radio .ant-radio-inner {
    background-color: transparent;
    border:2px solid $color-gray4;
    width: 18px;
    height: 18px;
}

.ant-radio-checked .ant-radio-inner:after{
    background-color: $color-yellow2;
}

.ant-radio-wrapper-checked span{
    color:#4d4e4e;
    font-weight: 500;
}