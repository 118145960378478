.#{$ui}-slider {
    width: 100%;
}

.slick-slide {
    > div {
        display: flex;
        justify-content: center;
        height: 100%;
    }
}

.testimonial-slider {
    width: 100%;
    $testimonialSlider: &;
    max-width: 1200px;

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-dots {
        width: 100vw;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transition: .6s background-color;

            &:not(:last-of-type) {
                margin-right: 10px;
            }

            overflow: hidden;
            border: 2px solid $color-gray3;
            
            &.slick-active {
                background-color: $color-gray3;
            }

            button {
                opacity: 0;
                display: none;
            }
        }
    }

    .slick-list {
        overflow: visible;
    }

    &__item {
        padding: 45px 30px;
        border-radius: 4px;
        box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.3);
        background-color: $color-white2;
        max-width: 390px;
        margin-right: 5px;
        margin-left: 5px;
        transition: background-color .3s;
        width: 100%;
        $testimonialSliderItem: &;

        &__title {
            font-size: 16px;
            font-weight: 900;
            margin-bottom: 5px;
            color: $color-black1;
        }

        &__quote {
            font-weight: 300;
            font-size: 14px;
            color: $color-gray10;
        }

        &__image {
            max-height: 40px;
        }

        &__quote {
            font-weight: 400;
            font-size: 18px;
            color: $color-gray10;
            line-height: 1.28;
            opacity: .8;
            transition: opacity .3s;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 1);

            .testimonial-slider__item__quote {
                color: $color-gray4;
                font-weight: 400;
                opacity: 1;
            }

        }

        @include media-breakpoint-down(md) {
            max-width: initial;
        }
    }
    
    .slick-slide {
        float: none;
        height: auto;

        &.slick-center {
            #{$testimonialSlider}__item {
                opacity: 1;
            }
        }
    }
    
}

.testimonial-slider2 {
    &__item {
        max-width: 700px;
    }

    @include media-breakpoint-down(sm) {
        .im-box {
            margin: 0 auto;
            max-width: 90vw;
        }
    }

    .slick-dots {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transition: .6s background-color;

            &:not(:last-of-type) {
                margin-right: 10px;
            }

            overflow: hidden;
            border: 2px solid $color-gray3;
            
            &.slick-active {
                background-color: $color-gray3;
            }

            button {
                opacity: 0;
                display: none;
            }
        }
    }
}

.product-gallery-slider, .product-gallery-extended-slider {
    &__item {
        width: 100%;
        background-color: #000;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        
        img {
            width: 100%;
        }
    }
}

.product-gallery-slider {
    &__item {
        // height: 510px;

        @include media-breakpoint-down(sm) {
            height: 230px;
        }
    }
}


.recommended-slider {
    overflow-x: hidden;
    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-list {
        margin: 0 -5px;
    }

    .slick-slide {
        float: none;
        height: auto;
        margin: 0 5px; 
    }
}

// For product Gallery Container
.#{$ui}-product-gallery {
     .#{$ui}-slider__arrows {
        
        &__prev, &__next {
            cursor: pointer;
            i {
                font-size: 50px;
                color: #fff;
            }
        }
    }

     .#{$ui}-slider__counter {
         position: absolute;
         bottom: 10px;
         left: 0;
         width: 100%;
         display: flex;
         justify-content: center;
        
        &__count {
            background-color: rgba(0, 0, 0, 0.7);
            color: $color-yellow2;
            padding: 5px 15px;
            font-size: 11px;
        }
        
    }
}


.#{$ui}-slider-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    &--vertical {
        flex-direction: column;
    }
    
    .#{$ui}-slider {
        
        &__arrows {
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            transform: translateY(-50%);
            $sliderArrows: &;
            
            &__prev, &__next {
                pointer-events: all;
            }

            &--type2 {   
                padding: 0;
                overflow: hidden;

                .#{$ui}-slider__arrows {

                    &__prev, &__next {
                        width: 60px;
                        height: 60px;
                        background-color: #000;
                        opacity: .5;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        transition: .3s opacity;
                        cursor: pointer;

                        i {
                            color: #fff;
                            font-size: 25px;
                        }

                        &:hover {
                            opacity: .8;
                        }
                    }

                    &__prev {
                        justify-content: flex-end;
                        padding-right: 13px;
                        transform: translateX(-50%);
                    }

                    &__next {
                        justify-content: flex-start;
                        padding-left: 13px;
                        transform: translateX(50%);
                    }
                }
            }

            &--type3 {
                padding: 15px;
                overflow: hidden;
                width: 60%;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: 40px;
                background-color: rgba(0,0,0, .5);
                top: auto;

                @include media-breakpoint-down(sm) {
                    bottom: 20px;
                    width: 90%;
                }

                .#{$ui}-slider__arrows {
                    
                    &__prev, &__next {
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        transition: .3s opacity;
                        cursor: pointer;

                        i {
                            color: #fff;
                            font-size: 25px;
                        }

                        &:hover {
                            opacity: .8;
                        }
                    }

                    &__prev {
                        justify-content: flex-end;
                        padding-right: 13px;
                    }

                    &__next {
                        justify-content: flex-start;
                        padding-left: 13px;
                    }

                    .im-slider__counter__count {
                        font-size: 13px;
                        color: $color-yellow2;
                    }
                } 
            }

            &--type4 {
                position: relative;
                left: 0;
                top: 0;
                transform: translateY(0);
                width: auto;
                justify-content: flex-start;
                margin-top: 10px;
                padding: 0;
                padding-left: 5%;

                .#{$ui}-slider__arrows {

                    &__prev {
                        margin-right: 60px;
                    }

                    &__prev, &__next {
                        cursor: pointer;

                        i {
                            color: $color-gray2;
                            font-size: 40px;

                            &:hover {
                                color: $color-yellow2;
                            }
                        }
                    }
                }

                
            }
        }
    }
}