.#{$ui}-breadcrumb {
    overflow-x: auto;
    
    // Hide scroll bar
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: transparent;
        flex-wrap: nowrap;

        .breadcrumb-item {
            font-size: 12px;
            font-weight: 500;
            color: $color-gray3;
            margin-bottom: 4px;
            flex-shrink: 0;

            a {
                font-size: inherit;
                font-weight: inherit;
                color: inherit;
            }

            & + .breadcrumb-item {

                &:before {
                    content: "\f105";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 9px;
                    position: relative;
                    top: 2px;
                }

            }
        }
    }
}
