.#{$ui}-select {
    display: flex;
    width: 100%;
    font-size: 16px;
    position: relative;
    flex-wrap: nowrap;
    @extend .#{$ui}-form-component;
    border-bottom: 1px solid $color-gray2;
    padding-top: 16px;
    position: relative;
    $select: &;
    min-height: 48px;
    
    &:not([data-placeholder]) {
        padding-top: 0;
        margin-bottom: 0;
    }
    
    select {
        width: 100%;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
        box-sizing: content-box;
        -moz-appearance: none;
        padding-right: 20px;
        background: none;
        border: none;
        font-weight: 700;
        font-size: inherit;
        margin-bottom: 8px;
        color: $color-gray11;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;

        i {
            color: $color-gray3;
        }
    }

    &:before {
        content: attr(data-placeholder);
        display: block;
        position: absolute;
        bottom: calc(100% - 2em);
        left: 0;
        transition: all .3s;
        z-index: 1;
        pointer-events: none;
        font-size: inherit;
        color: $color-gray3;
        font-weight: 400;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: $color-yellow2;
        transition: all .2s;
    }

    &--active, &--filled {
        &:before {
            bottom: calc(100% - 1em);
            font-size: 12px;
        }

        &:after {
            width: 100%;
        }
    }

     &--filled {
        &:after {
            background-color: $color-gray2;
        }
    }

    &--error {
        border-color: $color-red1 !important;

        // &:before {
        //     color: $color-red1;
        // }

        &:after {
            background-color: $color-red1;
        }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;

        &[data-disabled-like-text] {
            opacity: 1;
            border-bottom: none;
            margin-bottom: 0;

            &:after {
                display: none;
            }

            #{$select} {

                &__icon {
                    opacity: 0;
                }
            }

            select {
                margin-bottom: 0;
            }
        }
    }

    &--no-bottom-line {
        border-bottom: none;

        &:after {
                display: none;
        }

        select {
            margin-bottom: 0;
        }
    }


    &--inline {
        width: auto;
        display: inline-flex;
        flex-grow: 1;
    }

    &--auto-height {
        min-height: auto;
    }

     &--plain {
        border-bottom: none;
        margin: none;
        padding: 0;

         &:after {
            display: none;
        }

       select {
            margin: 0 !important;
        }       
    }
    
}

.#{$ui}-select-blank {
    color: $color-gray11;
    font-weight: 900;
}
.ant-select-arrow {
    margin-right: 10px;
}
.ant-select-clear {
    margin-right: 10px;
}
