.#{$ui}-table {
    width: 100%;
    font-size: 13px;
    min-width: 425px;

    thead {
        th {
            color: $color-gray3;
            font-weight: 900;
        }
    }

    tbody {
        &:before {
            content: "";
            display: block;
            height: 25px;
            width: 100%;
        }

        tr {
            td {
                padding: 5px;
            }
        }
    }

    &--striped {
        tbody {
            tr {
                &:nth-child(odd) {
                    border: none;
                    background-color: $color-white2;
                }
            }
        }
    }
}