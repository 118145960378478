.#{$ui}-checkbox {
    position: relative;
    width: 100%;
    display: flex;
    $checkbox: &;

    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;

        &:checked {
            & + #{$checkbox}__check {
                background-color: $color-yellow2;
                border-color: $color-yellow2;

                i {
                    opacity: 1;
                }
            }

            // & + #{$checkbox}__check + #{$checkbox}__text  {
            //     color: $color-gray4;
            //     font-weight: 500;
            // }
        }
    }

    label {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &__text {
        display: inline-flex;
        font-size: 14px;
        color: $color-gray3;
    }

    &__check {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid $color-gray3;
        border-radius: 5px;
        display: inline-flex;
        margin-right: 9px;
        justify-content: center;
        align-items: center;
        transition: background-color .3s;

        i {
            opacity: 0;
            color: #fff;
            font-size: 12px;
            transition: opacity .3s;
        }
    }

    &--type2 {
        #{$checkbox} {
            &__check {
                border-radius: 2px;
                border-color: $color-gray4;
            }
        }
    }

    &--type3 {
        #{$checkbox} {
            &__check {
                border-radius: 2px;
                border-color: $color-gray2;
            }

            &__text {
               color: $color-gray4;
            }
        }
    }

    &--error {
        #{$checkbox} {
                &__check {
                    border-color: $color-red1;
                }
            }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }
}


.ant-checkbox .ant-checkbox-inner {
    background-color: transparent;
    border-radius: 2px;
    border:2px solid $color-gray4;
    width: 18px;
    height: 18px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-yellow2;
    border-color: $color-yellow2;
}

.ant-checkbox-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
