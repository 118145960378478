/*
 *
 * COMMON
 *
 */
 * {
    -webkit-overflow-scrolling: touch;
}

body, .body {
    background-color: $body-bg-color;
    position: relative;
}

ul {
    margin: 0;
    padding: 0;
    
    li {
        list-style: none;
    }
}

ol:not(.breadcrumb) {
    margin: 0;
    padding: 0;
    padding-left: 14px;

    li {
        font-size: 14px;
        line-height: 1.4;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        em {
            font-weight: 500;
        }
        
        h2,h3,h4 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        p {
            margin-left: 20px;
        }

        & > ol {
            padding-left: 50px;

            li {
                list-style: circle;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 30px;
            }
        }
    }

    &.ol-type2 {
        margin-left: 15px;
        
        li {
            margin-bottom: 0;
            
            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
        }
    }
}

 .logo {
     width: 161px;
     height: 45px;
     cursor: pointer;

     &.h-35 {
         height: 35px;
     }

     &.h-45 {
         height: 45px;
     }
 }

 // BANNER
 .banner {
     position: relative;
     width: 100%;
     background-color: #000;
     color: #fff;
     font-size: 50px;
     $banner: &;

     .ant-carousel {
        color: #fff;
        font-size: 50px;
        line-height: 1.2;
     }

     .banner__inner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
        flex-direction: column;
        display: flex !important;
        height: 100vh;
        position: relative;
        
        &:before {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 0;
            background-image: linear-gradient(29deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.14)), linear-gradient(121deg, rgba(0, 0, 0, 0.21), rgba(0, 0, 0, 0.24));
            pointer-events: none;
        }
     }

     .banner__title {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        width: 60vw;
        color:white;
        line-height: 1.2;

        &--boldest {
            font-weight: 900;
        }
     }  

     .banner__bottom {
         z-index: 1010;
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         font-size: 1rem;
     }

     .banner__description {
        width: 35vw;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 1em;
    }

     .banner__detail  {
         flex-grow: 1; 
         display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2.2em;
        margin-bottom: 2.2em;
        z-index: 1010;
     }

      &--smaller {
         #{$banner} {
             &__inner {
                 height: 480px;
             }
         }
     }

     @include media-breakpoint-down(lg) {
        .banner__title {
            width: 50vw;
            font-size: .9em;
         }

         .banner__description {
             width: 50vw;
         }
     }

     @include media-breakpoint-down(md) {
        .banner__title {
            width: 70vw;
            font-size: .9em;
         }

         .banner__description {
             width: 70vw;
         }
     }

     @include media-breakpoint-down(sm) {
        .banner__bottom {
            position: relative;
        }

        // .banner__detail {
        //     margin-top: 0;
        // }

        .banner__title {
            width: auto;
            font-size: .6em;
         }

         .banner__description {
             width: auto;
             font-size: 14px;
         }
     }
 }

 // CLIENTS
.clients {
    width: 100%;
    padding: 15px 0px;
    background-color: $color-white3;
    box-shadow: 0 -20px 40px 0 rgba(0, 0, 0, 0.33);

    .client {
        height: 70px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        outline: none;
        transition: border-color .3s;
        border: 1px solid transparent;
        border-radius: 4px;
       
        img {
            height: 30px;
        }

        &:hover {
            border-color: rgba(228, 228, 228, .4);
        }

        @include media-breakpoint-up(xl) {
             -webkit-filter: grayscale(1);
             opacity: .5;
            filter: grayscale(1);   

             &:hover {
                -webkit-filter: grayscale(0);
                opacity: 1;
                filter: none;
            }
        }
    }
 }

 // SECTION
 .section {
    background-color: $color-white2;
    padding: 120px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;

    &__header {
        margin-bottom: 60px;
        text-align: center;
        max-width: 592px;
        padding: 0 10px;

        &__title {
            font-size: 30px;
            font-weight: 900;
            color: $section-heading-color;
            margin-bottom: 45px;

            &--mb-45 {
                margin-bottom: 45px;
            }

            &--mb-20 {
                margin-bottom: 20px;
            }
        }

        &__description {
            font-size: 20px;
            font-weight: 300;
            color: $section-description-color;
        }
    }

    &__body {
        width: 100%;
    }

    &--transparent {
        background: transparent;
    }

    &--shadow {
        box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.12);
    }

    @include media-breakpoint-down(sm) {
        padding: 60px 0;

        &__header {

            &__title, &__description {
                text-align: center;
            }

            &__title {
                font-size: 24px;
                text-align: center;
            }

            &__description {
                font-size: 16px;
            }
        }
    }
 }

 // FEATURES
 .features {
     &__feature {
         width: 100%;
         padding: 10px 40px;
         height: 231px;
         border-radius: 4px;
         border: 1px solid $color-gray2;
         display: flex;
         align-items: center;
         flex-direction: column;
         justify-content: center;
         text-align: center;
         transition: all .3s;
         $feature: &;

         
         &--v-align-start {
             justify-content: flex-start;
         }

         &--p-20 {
             padding: 20px;
         }

         &--p-30 {
             padding: 30px;
         }

         &--height-auto {
             height: auto;
         }

         &--height-100 {
             height: 100%;
         }

         &--shadow {
             box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
             border: none;
         }

         &--height-280 {
             height: 280px;
         }

         &__image, &__image-hover {
             width: 45px;
             height: 45px;
             margin-bottom: 35px;

             &.mw-80 {
                 width: auto;
                 max-width: 80px;
             }

             @include media-breakpoint-down(md) {
                 margin-bottom: 20px;
             }
         }

         &__image-hover {
             display: none;
         }

         &__icon { 
            font-size: 45px;
            margin-bottom: 35px;

            &.mw-80 {
                width: auto;
                max-width: 80px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }
         }

         &__title {
             font-size: 16px;
             font-weight: 900;
             color: $color-gray4;
             margin-bottom: 10px;
         }

         &__description {
             font-size: 14px;
             font-weight: 400;
             color: $color-gray4;
             max-width: 85%;
         }

         &:hover, &--hover {
             background-color: #fff;
             box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.66);
             border-color: transparent;

           #{$feature} {
                &__image {
                   display: none;
                }

                &__image-hover {
                    display: block;
                }

                &__icon{
                    color: $color-yellow2;
                }

                &__indicator {
                    color: $color-yellow2;
                }
           }
         }

         &--space-between {
             justify-content: space-between;
             padding-top: 40px;
             padding-bottom: 40px;
         }

         @include media-breakpoint-down(md) {
             padding-left: 10px;
             padding-right: 10px;
         }
     }
 }

 .image-shadow-container {
     position: relative;
     img {
         width: 100%;
     }

     &:after {
         content: "";
         width: 100%;
         height: 100%;
         z-index: 1;
         background: transparent;
         position: absolute;
         left: 0;
         top: 0;
         box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.12);
         pointer-events: none;
     }
 }

 .separator {
     opacity: .4;
     position: relative;

     &--vertical {
         width: 1px;
         background-color: $color-gray2;
         height: 100%;
         display: inline-flex;
         min-height: 340px;
     }

     &--absolute {
         position: absolute;
         left: 0;
         top: 0; 
     }

     &--mh-40 {
         min-height: 40px;
     }

     &--mh-auto {
         min-height: auto;
     }

     &--neg-margin-45 {
         margin-left: -45px;
         width: calc(100% + 45px) !important;
     }

     &--neg-margin-30 {
         margin-left: -30px;
         width: calc(100% + 60px) !important;
     }

     &--neg-margin-20 {
         margin-left: -20px;
         width: calc(100% + 40px) !important;
     }

     &--neg-margin-15 {
         margin-left: -15px;
         width: calc(100% + 30px) !important;
     }

     &--neg-margin-10 {
         margin-left: -10px;
         width: calc(100% + 20px) !important;
     }

     &--absolute-rem-padding {
        top: -1.5rem;
        height: calc(100% + 3rem);
     }

     &--opacity-1 {
         opacity: 1;
     }

     &--margin-y-20 {
         margin-top: 20px !important;
         margin-bottom: 20px !important;
     }

     &--white2 {
         background-color: $color-white2;
     }

     &--horizontal {
         width: 100%;
         background-color: $color-gray2 !important; 
         height: 1px !important;
         display: flex !important;
     }
     
     &__text {
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         background: #fff;
         padding: 0 12px;
     }
 }

 .cols-have-separator {
     > [class*=col] {
        position: relative;

        &:not(:last-child) {
            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: 120%;
                background-color: $color-white1;
                transform: translateY(-50%);
            }
        }
     }

     &-no-mobile {
        @include media-breakpoint-down(lg) {
            > [class*=col] {
                &:after {
                    display: none !important;
                }
            }
        }
    }
 }

 .circle {
     display: inline-flex;
     width: 110px;
     height: 110px;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     background-color: #fff;
     box-shadow: 0 0 29px 0 #cfcfcf;

     img {
         width: 60%;
     }
 }

 .image-quote-container {
     position: relative;     

    &__title {
        width: 100%;
        padding-top: 100px;
        background: linear-gradient(to right, #f2f2f2, #f0f0f0);

        h2 {
            position: relative;
            top: 50px;
            z-index: 1;
            font-size: 35px;
            font-weight: 300;
            width: 70%;
        }

        @include media-breakpoint-down(sm) {
             padding-top: 0;
             display: none;
         }
    }

    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .image-quote {
        position: absolute;
        padding: 16px 20px;
        display: none;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        z-index: 1;

        &--indicator-left {
            &:after {
                content: "";
                width: 24px;
                height: 24px;
                position: absolute;
                left: 10%;
                top: calc(100% - 4px);
                border-radius: 4px;
                transform: translateY(-50%) rotate(45deg);
                background: #fff;
            }
        }

        &--indicator-right {
            &:after {
                content: "";
                width: 24px;
                height: 24px;
                position: absolute;
                right: 10%;
                top: calc(100% - 4px);
                border-radius: 4px;
                transform: translateY(-50%) rotate(45deg);
                background: #fff;
            }
        }

        &--indicator-center {
            &:after {
                content: "";
                width: 24px;
                height: 24px;
                position: absolute;
                left: 50%;
                top: calc(100% - 4px);
                border-radius: 4px;
                transform: translate(-50%, -50%) rotate(45deg);
                background: #fff;
            }
        }

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }
}

.social {
    display: flex;
    flex-direction: row;

    a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0 !important;
        border-radius: 5px;
        background-color: $color-gray4;

        margin-right: 4px;

        &:last-of-type {
            margin-right: 0;
        }

        img, svg {
            width: 50%;
            height: 50%;
            object-fit: contain;
        }

        i {
            font-size: 20px;
            color: #fff; 
            transition: color .4s;
        }

        &:hover {
            background-color: $color-gray12;

            i {
                color: $color-yellow2;
            }
        }

    }
}

.sign {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 50px;
        color: #fff;
    }

    &--success {
        background-color: $color-green2;
    }

    &--error {
        background-color: $color-red1;
    }

    &--warning {
        background-color: $color-yellow2;
    }
}

.img-responsive {
    width: 100%;

    &--height {
        width: auto;
        height: 100%;
    }
}


// Overlay
.#{$ui}-overlay {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $color-gray4;
    z-index: 1020;
    opacity: .3;
}

.#{$ui}-content-nav {
    display: flex;
    flex-wrap: wrap;

    &__item {
        font-size: 1em;
        font-weight: 400;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &--active {
            font-weight: 900;
            pointer-events: none;
        }
    }
}

.bg-gray3-v-half {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 60%;
        width: 100%;
        background-color: $color-gray3;
        z-index: -1;
    }
}

.fixed-bg {
    background-attachment: fixed;
}

ul {
    &.has-child-margins {
        li {
            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }
    }
}

.user-profile-image {
    width: 57px;
    height: 57px;
    min-width: 57px;
    min-height: 57px;
    background-color: $color-gray2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.bg-cover-container {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.fa-spin {
  animation-duration: 1s; 
}

.list-item-v {
     margin-bottom: 5px;
}

.#{$ui}-bidding-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    $biddingBox: &;
    margin-bottom: 30px;
    position: relative;

    &__head {
        min-height: 40px;
        display: flex;
        width: 70%;
        background-color: $color-gray12;
        border-radius: 4px;
        color: $color-yellow2;
        padding: 10px;
        line-height: 1.4;

        @include media-breakpoint-down(sm) {
            width: 90%;
            position: sticky;
            top: 55px;
            z-index: 1;

            &--sticky {
                width: 100vw;
                left: -$grid-gutter-width;
                border-radius: 0;
                box-shadow: 0 3px 10px 0 rgba(175, 175, 175, 0.65);
                transition: all .2s;
            }
        }
    }

    .#{$ui}-box {
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 20px;
            background-color: $color-gray12;
            border-radius: 4px;
            z-index: -1;
        }
    }

    &--green {

        #{$biddingBox} {

            &__head {
                background-color: $color-green2;
                color: #fff;
            }
        }

        .#{$ui}-box {
            &:before {
                background-color: $color-green2;
            }
        }
    }

    &--red {
         #{$biddingBox} {

            &__head {
                background-color: $color-red1;
                color: #fff;
            }
        }

        .#{$ui}-box {
            &:before {
                background-color: $color-red1;
            }
        }
    }
}


.#{$ui}-bidding-head {
        min-height: 40px;
        display: flex;
        width: 100%;
        background-color: $color-gray12;
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $color-yellow2;
        padding: 10px;
        line-height: 1.4;
        $biddingHead: &;

        &--gray {
            background-color: $color-gray2;
            color: $color-gray12;
        }

        &--green {
            background-color: $color-green2;
            color: #fff;
        }

        &--red {
            background-color: $color-red1;
            color: #fff;
        }
    }

    .go-top-button {
        font-size: 10px !important;
        color: #fff;
        position: absolute;
        right: 30px;
        top: 30px;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: opacity 1s;

        &.active {
            opacity: 1;
        }

        i {
            font-size: 18px !important;
            color: $color-gray3;
            margin-bottom: 5px;
        }
    }


.#{$ui}-product-card {
    background-color: #fff;
    padding:20px;
    padding-bottom: 30px;
    box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.66);
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all .4s;
    height: 100%;

    &__image {
        width: 100%;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 18px;
        font-weight: 900;
        color: $color-gray4;
        margin-bottom: 15px;
        line-height: normal;
    }

    &__props {
        margin-bottom: 20px;

        .row {
            margin-bottom: 8px;
        }

        &__title, &__value {
            font-size: 15px;
        }

        &__title {
            font-weight: 400;
        }

        &__value {
            font-weight: 700;
        }
    }

    a {
        color: inherit;

        i {
            color: $color-black1;
        }
    }

    &__price {
        position: relative;
        text-align: center;
        padding-top: 30px;
        margin-top: auto;

        &:before {
            content: "";
            position: absolute;
            left: -20px;
            top: 0;
            width: calc(100% + 40px);
            height: 1px;
            background-color: $color-white1;
        }

        &__value {
            font-size: 35px;
            font-weight: 900;  
            margin-bottom: 5px; 
        }

        &__title {
            font-size: 15px;
            font-weight: 400;
        }
    }

    &:hover {
        box-shadow: 0 0 40px 0 rgba(95, 95, 95, 0.66);
    }
}

.read-more-text {
    display: none;

    &--active {
        display: block;
    }
}


.thumbnail-container {
    overflow: hidden;
    position: relative;

    &--radius {
        border-radius: 4px;
    }
    
    &--w-100 {
        width: 100%;
    }

    &--h-160 {
        height: 160px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__title {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        z-index: 1;
        width: 100%;
        text-align: center;
        font-size: 16px;

        &--gray {
            background: $color-gray4;
            color: $color-yellow2;
        }

        &--green {
            background: $color-green2;
            color: #fff;
        }

        &--red {
            background: $color-red1;
            color: #fff;
        }
    }
}


.filter-menu, .mobile-sort-menu {
    position: sticky;
    // Current Header height + 10px gap
    top: 145px;
    display: flex;
    flex-direction: column;
    

    &--active {
        display: block !important;

        & + .mobile-menu-overflow-close {
            display: block !important;
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed !important;
        left: 0;
        top: 0;
        padding-top: 55px !important;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        overflow-y: auto;
        display: none;

        .im-box {
            border-radius: 0 !important;
            min-height: calc(100vh - 55px);
        }

        &__actions {
            margin-top: auto;
        }
    }
}

.mobile-sort-menu {
    display: none;
}

.mobile-sort-menu, .filter-menu {
    &__actions {
        position: sticky;
        bottom: 0;
        padding: 15px;
        box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
        background: #fff;
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .ant-radio-wrapper{
        margin-top: 15px;
    }
}

.filter {
    padding: 20px;
    border-bottom: 1px solid $color-gray2;

    &__title {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__active-items {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray2;
        overflow-y: auto;
        max-height: 235px;
    }

    &__active-item {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__items { 
        overflow-y: auto;
        max-height: 235px;

        .ant-radio-wrapper{
            margin-top: 5px;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.mobile-menu-overflow-close {
    position: fixed;
    z-index: 1030;
    right: 15px;
    top: 15px;
    display: none !important;
    font-size: 25px;
    cursor: pointer;
    background-color: white;
    text-align: end;
    width: 80px;

    &.active {
        display: block;
    }
}

.dot-indicator {
    width: 8px;
    height: 8px;
    background: $color-yellow2;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50%;
}

@include media-breakpoint-up(md) {
    /* Custom Scrollbar */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: $color-white1;
        border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
        background: $color-gray2;
        border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $color-gray2;
    }
}

.#{$ui}-blink {
    animation: blink 1.5s ease infinite;

    @keyframes blink {
        30% { opacity: 1; }
        50% { opacity: 0.1; }
        70% { opacity: 1; }
    }
}
