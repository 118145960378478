.#{$ui}-rate {
    display: inline-flex;

    &__point {
        width: 15px;
        height: 6px;
        margin-left: 1px;
        background-color: $color-gray2;

        &__first {
            border-top-left-radius: 5.5px;
            border-bottom-left-radius: 5.5px;
        }
        
        &__last {
            border-top-right-radius: 5.5px;
            border-bottom-right-radius: 5.5px;
        }

        i {
            font-size: 12px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }

        &--green {
            background-color: $color-green1;
        }

        &--yellow {
            background-color: $color-yellow2;
        }

        &--red {
            background-color: $color-red2;
        }
    }
} 

.#{$ui}-rate-list {
    display: flex;
    flex-direction: column;
    max-width: 330px;

    &__item {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        padding: 5px 10px;
        background-color: $color-white2;
        color: $primary-text-color;
        text-align: left;
        
        &:nth-child(even) {
            background-color: $color-white1;
        }

        .#{$ui}-rate {
            margin-right: 12px;
        }
    }   



} 