.#{$ui}-login {
    min-height: 540px;
    display: flex;
    align-items: stretch;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
    font-size: 15px;
    font-weight: 400;

    a:not(.im-button) {
        font-weight: 400;
    }

    > .row {
        width: 100%;
    }

    &__cover {
        width: 100%;
        min-height: 100%;
        background-color: $color-gray4;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 43px;

        &--heading-top-p {
            align-items: flex-start;
            padding-top: 40px;
        }

        &__heading {
            font-size: 25px;
            font-weight: 400;
            color: $color-yellow2;
        }
    }

    &__form {
        padding-top: 60px;
        padding-bottom: 60px;
        width: 100%;
        min-height: 100%;
        background-color: #fff;
    }
}

.#{$ui}-login-footer {
    margin-top: 15px;
    
    select {
        color: $color-gray3;
        font-weight: 900;
    }
}

nav.horizontal-links {
    display: inline-flex;
    flex-direction: row;
        
    a {
        font-weight: 500;
        font-size: 13px;
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}