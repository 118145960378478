.product {
    padding: 30px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &__image {
        width: 100%;
    }

    &__title {
        font-size: 15px;
        font-weight: 900;
        margin-bottom: 10px;
    }

    &__prop {
        margin-bottom: 10px;
        $product-prop: &;
        
        &__name {
             font-size: 13px;
             font-weight: 300;
        }

         &__value {
            font-size: 13px;
            font-weight: 500;
         }

         &--marked {
            #{$product-prop} {
                &__name {
                    font-weight: 400;
                }

                &__value {
                    font-weight: 700;
                }

                &__name, &__value {
                    color: $color-gray4;
                }

            }
         }
    }

    &__description {
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 13px;
        color: $color-gray3;
    }

    &__expertise-description {
        margin: 0;
        font-size: 12px;
        color: $color-gray3;
    }

    &__expertise-logo {
        width: 45px;
    }

    &__auction {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: -30px;
            width: 1px;
            height: calc(100% + 60px);
            background-color: $color-white1;
        }

        &__price {
            font-size: 35px;
            font-weight: 900;
            margin-bottom: 5px;
        }

        &__description {
            font-size: 15px;
            font-weight: 400;
        }

        &__price-inc {
            margin-top: 40px;
            font-size: 14px;
            color: $color-gray3;
        }

        @include media-breakpoint-down(md) {
            &:before {
                top: 50%;
                height: 70%;
                transform: translateY(-50%);
            }
        }

        @include media-breakpoint-down(sm) {
            &:before {
                display: none;
            }
        }
        
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 10px;
    }
}