.video-container, .media-container {
    height: 430px;
    width: 100%;
    border-radius: 10px;
    background-color: $color-white4;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.66);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    $videoContainer: &;
    position: relative;

    &__button-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        img {
            max-width: 100px;
        }
    }

    &--no-radius {
        border-radius: 0;
    }

    &--bg-white {
        background-color: #fff;
    }

    &--height-165 {
        height: 165px;
    }

    &--smaller {
        height: 240px;

        #{$videoContainer} {
            &__button-play {
                padding: 0;

                svg {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    &--text {
       text-align: center;
       z-index: 1;

        &:before{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #000;
            opacity: .3;
            z-index: -1;
        }
    }

    .video-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &--bg-gray {
            background-color: rgba(90, 90, 90, 0.2)
        }

        &__icon {
            font-size: 100px;
            color: #fff;
        }
    }

    .a-content {
        position: absolute;

        &--top {
            top: 28px;
        }

        &--right {
            right: 46px;
        }

         &--bottom {
            bottom: 28px;
        }

         &--left {
            left: 46px;
        }
    }

    &--no-shadow {
        box-shadow: none;
    }


    @include media-breakpoint-down(sm) {
        height: 215px;
    }
}

.float-image {
        width: 391px;
        
        &--right {
            float: right;
            margin-left: 30px;
            margin-bottom: 30px;
        }

        &--left {
            float: left;
            margin-right: 30px;
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            float: none;
        }
    }