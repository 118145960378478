.#{$ui}-dropdown {
    $dropdown: &;

    &__button {
        padding: 20px 28px 20px 20px !important;

        &__arrow {
            margin-left: 22px;

            i {
                font-size: 23px;
            }
        }
    }

    &.show {
       #{$dropdown}  {
            &__button {
                background-color: $color-white2;
                border-radius: 4px;
                color: $primary-text-color;
            }
       }

       .dropdown-menu {
                top: -8px !important;
                background-color: $color-white2;
                border: none;
                padding-bottom: 0;
            }
       
    }

}


.#{$ui}-header {
        .#{$ui}-dropdown {
            $dropdown: &;

        &__button {
            padding: 15px !important;

            &__arrow {
                margin-left: 22px;

                i {
                    font-size: 23px;
                }
            }
        }

        .dropdown-menu {
            .dropdown-item {
                padding: 15px 30px;
                position: relative;
                // border-bottom: 1px solid $color-gray9;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                    width: calc(100% - 40px);
                    height: 1px;
                    background-color: $color-gray9;
                }

                &:active, &:focus {
                    outline: none;
                    background-color: transparent;
                    color: inherit;
                }

                &:last-child {
                    border-bottom: none;
                }

                .dropdown-item-inner-item {
                    display: flex;
                    padding: 15px 20px;
                    font-size: 13px;
                    align-items: center;
                    color: $primary-text-color;

                    &.padding-15 {
                        padding: 15px;
                    }

                    &:hover {
                        background-color: $color-gray9;
                    }
                }

                &.no-padding {
                    padding: 0;
                }

                &.only-padding-bottom-15 {
                    padding: 0;
                    padding-bottom: 15px;
                }

                &.striped-inner-items {
                    .dropdown-item-inner-item {

                        &:hover {
                            background-color: transparent;
                        }

                        &:nth-child(even) {
                            background-color: $color-gray9;;
                        }
                    }
                }
                
            }
        }

        &.show {
        #{$dropdown}  {
                &__button {
                    background-color: $color-white2;
                    border-radius: 4px;
                    color: $primary-text-color;
                }
        }

        .dropdown-menu {
                    top: 50px !important;
                    background-color: $color-white2;
                    border: none;
                }
        
        }
    }
}