footer {
    padding: 90px 0 0 0;
    background: $color-black2;
    color: $color-gray3;
    margin-top: auto;
    position: relative;

    p {
        font-size: 14px;
    }

    nav {
        display: flex;
        flex-direction: column;
        color: inherit;

        &.nav--horizontal {
            flex-direction: row;
            font-size: 13px;
            
            a {
                font-weight: 700;
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;

                }
            }
        }

        a {
            color: $color-gray3;
            font-weight: 400;
            transition: color .2s;

            &:hover, &:focus, &:active {
                color: #fff;
            }

            &:not(:last-of-type) {
                margin-bottom: 7px;
            }
        }
    }

    .im-input {
        border-color: $color-gray10;

        input {
            color: #fff;
        }
    }

    .im-select {
        border-color: $color-gray10;
        
        select {
            color: #fff;
        }

        &__icon {
            i {
                color: $color-gray10;
            }
        }

        .bootstrap-select {
            .dropdown-toggle {
                color: #fff;
            }
        }
    }

    .im-checkbox {
        &__text {
            color: $color-gray2;
        }
    }

    .im-accordion {
        background-color: transparent;
        border-bottom: none;
        box-shadow: none;

        &__head {
            border-bottom: none;

            padding: 0;

            &__title {
                color: $color-gray2;
                margin-bottom: 10px;
            }

            &__toggle-icon {
                display: none;

                i {
                    font-size: 18px;
                }
            }

        }

        &__body {
            padding: 0;
        }

        @include media-breakpoint-down(xs) {
            &__head {
                padding: 20px 10px;
                border-bottom: 1px solid $color-gray12;

                &__title {
                    margin-bottom: 0;
                }

                &__toggle-icon {
                    display: block;
                }
            }

            &__body {
                padding: 0 10px;

                nav {
                    a {
                        &:not(:last-of-type) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

.footer-2 {
    padding: 20px 0;
    background-color: transparent;
    border-top: 1px solid $color-gray2;
    width: 100%;

    .horizontal-links {
         a {
            &:hover, &:focus, &:active {
                color: $color-yellow2 ;
            }
        }
    }
   
}

.footer-more-text-container {
    h4 {
        color: $color-gray2;
    }
}
