.#{$ui}-list {
    $uiList: &;

    li {
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }

    &__label {
        font-size: 12px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    &__value {
        font-size: 13px;
        font-weight: 700;
        word-break: break-all;
        padding-left: 10px;
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }

        textarea {
            word-break: normal;
        }
    }

    &--type2 {
        align-items: flex-end;

        li {
            display: flex;
            padding-bottom: 11px;
            margin-bottom: 11px;
            border-bottom: 1px solid $color-white2;
            align-items: center;

            &:last-of-type {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &--type3 {
        align-items: flex-end;

        #{$uiList} {
            &__label {
                flex-grow: initial;
                font-weight: 700;
            }

            &__value {
                font-weight: 400;
                width: auto;
            }

            &__label, &__value {
                font-size: 14px;
            }
        }

        li {
            display: flex;
            margin-bottom: 11px;
            align-items: center;

            &:last-of-type {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &--type4 {
        align-items: flex-end;

        #{$uiList} {
            &__label {
                flex-grow: initial;
                font-weight: 400;
            }

            &__value {
                font-weight: 900;
                width: auto;
            }

            &__label, &__value {
                font-size: 13px;
                color: $color-gray3;
            }
        }

        li {
            display: flex;
            margin-bottom: 4px;
            align-items: center;

            &:not(:last-of-type) {
                margin-bottom: 4px;
            }
        }
    }

     &--type5 {
        align-items: flex-end;

        #{$uiList} {
            &__label {
                flex-grow: initial;
                font-weight: 400;
            }

            &__value {
                font-weight: 500;
                width: auto;
            }

            &__label, &__value {
                font-size: 13px;
            }
        }

        li {
            display: flex;
            margin-bottom: 11px;
            align-items: center;

            &:last-of-type {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &--type6 {
        li {
            font-size: 13px;
            padding: 5px 15px;
            margin-left: -15px;
            width: calc(100% + 30px) !important;
            color: $primary-text-color;
            margin-bottom: 5px !important;

            &:nth-child(odd) {
                background-color: $color-white2;
            }
            &:nth-child(even) {
                background-color: #fff;
            }
        }
    }

    &--horizontal {
        display: flex;
        

        li {
            position: relative;

            &:not(:last-child) {
                padding-right: 20px;
                margin-right: 20px;

                &:after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 100%;
                    background: $color-white1;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        #{$uiList} {
            &__label {
                font-weight: 400;
            }

            &__value {
                flex-grow: initial;
                font-weight: 900;
                color: $color-gray10;
                margin-bottom: 5px;
                width: 100%;
                padding-left: 0;
            }

            &__label, &__value {
                font-size: 14px;
            }
        }
    }
}
