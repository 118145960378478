.ant-tabs-tab-btn , .#{$ui}-tabs {
    position: relative;
    $tabs: &;

    &__tab {
        $tab: &;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border: 1px solid $color-gray2;
        border-radius: 4px;
        font-weight: 500;
        font-size: 18px;
        transition: background-color .3s;
        padding: 0 45px;
        color: $color-gray4;
        min-height: 130px;

        &__image, &__image-hover {
            width: 40px;
            height: 40px;
            margin-right: 40px;
            flex-shrink: 0;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__image-hover {
            display: none;
        }

        &:hover, &:active, &:focus {
            color: $color-gray4;

            #{$tab} {
                &__image {
                    display: none;
                }

                &__image-hover {
                    display: block;
                }
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
         
        &:hover, &--active {
            background-color: #fff;
            box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
            font-weight: 900;
            border-color: #fff;
        }
    }

    @include media-breakpoint-down(md) {
        &__tab__image, &__tab__image-hover {
            margin-right: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

        &__tab {
            width: auto;
            min-height: auto;
            display: inline-flex;
            padding: 20px;
        }
    }

    &--fluid {
        width: 100%;
    }

    &--as-heading {
        position: relative;
        // top: 4px;
    }

    &--horizontal-bar {
        padding: 0 40px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
        margin-bottom: 20px;

        #{$tabs} {
            &__tab {
                width: auto;
                display: inline-block;
                min-height: auto;
                border: none;
                background: transparent;
                box-shadow: none;
                font-size: 14px;
                margin-bottom: 0;
                color: $color-gray3;
                padding: 20px 0;
                position: relative;
                font-weight: 900;
                

                &:not(:last-of-type) {
                    margin-right: 40px;
                }

                &:hover {
                    background-color: transparent;
                    border: none;
                }

                &:hover, &:active, &:focus {
                    color: $color-gray4;
                }

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 7px;
                    border-radius: 3.5px;
                    background-color: $color-yellow2;
                    transform: translateY(-50%);
                    opacity: 0;
                    transition: .3s opacity;
                    z-index: 1;
                }

                &--active {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &--horizontal-bar-type2 {
        #{$tabs} {
            &__tab {
                width: 220px;
                height: 60px;
                min-height: auto;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: $color-white2;
                border: none;
                font-size: 14px;
                margin-bottom: 0;
                color: $color-gray3;
                padding: 5px;
                position: relative;
                font-weight: 900;
                box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
                border-bottom-right-radius: 0;                
                border-bottom-left-radius: 0;                

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

    
                &:hover, &:active, &:focus {
                    color: $color-gray4;
                }

                &--active {
                   background: #fff;
                   color: $color-gray4;
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: flex;
            
            #{$tabs} {
                &__tab {
                    flex-grow: 1;
                    flex-basis: 0;
                    max-width: 100%;
                    min-height: auto;
                    width: 100%;
                    height: 50px;
                    white-space: initial;
                    text-align: center;

                    &:not(:last-of-type) {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}