svg {
    width: 100%;
    display: block;

    &.wh-15 {
        width: 15px;
        height: 15px;
    }

    &.wh-20 {
        width: 20px;
        height: 20px;
    }

    &.wh-25 {
        width: 25px;
        height: 25px;
    }

    &.wh-30 {
        width: 30px;
        height: 30px;
    }

    &.wh-60 {
        width: 60px;
        height: 60px;
    }

    &.w-40 {
        width: 40px;
    }

    &.w-60 {
        width: 60px;
    }

    &.h-60 {
        height: 60px;
    }

    &.inline-block {
        display: inline-block;
    }

    &.flag-icons {
        display: inline-block;
        width: 30px;
        height: 20px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
    }
}

