.#{$ui}-form-component {

    input, select {
        outline:  none !important;
    }

}

select {
    outline: none;
    opacity: none;
    background: transparent;
    border: none;
    text-indent: 0px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

// Animate Css Additions
.animated {
    &.delay-0p5s {
        animation-delay: 0.5s;
    }

    &.delay-1p5s {
        animation-delay: 1.5s;
    }

    &.delay-2p5s {
        animation-delay: 2.5s;
    }

    &.delay-3p5s {
        animation-delay: 3.5s;
    }

    &.delay-4p5s {
        animation-delay: 4.5s;
    }
}

