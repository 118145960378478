.#{$ui}-input {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    @extend .#{$ui}-form-component;
    position: relative;
    font-size: 16px;
    border-bottom: 1px solid $color-gray2;
    padding-top: 1em;
    $input: &;
    min-height: 48px;

    &:not([data-placeholder]) {
        padding-top: 0;
        min-height: auto;

        input {
            margin-bottom: 5px;
        }
    }

    input {
        border: none;
        width: 100%;
        padding-bottom: 8px;
        position: relative;
        line-height: 1;
        font-size: inherit;
        font-weight: 700;
        color: $color-gray11;
        background-color: transparent;

        &[placeholder] {
            padding: 0;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // For Firefox
    input[type=number] {
        -moz-appearance: textfield;
    }

     &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: $color-yellow2;
        transition: all .2s;
    }
    
    &:before {
        content: attr(data-placeholder);
        display: block;
        position: absolute;
        bottom: calc(100% - 2em);
        left: 0;
        transition: all .3s;
        z-index: 1;
        pointer-events: none;
        font-size: inherit;
        color: $color-gray3;
        font-weight: 400;
    }

    &--prefix, &--suffix {
        input {
            width: inherit;
            flex-grow: 1;
        }

        #{$input} {
            &__prefix {
                flex-shrink: 1;
                margin-right: 5px;
                padding-right: 13px;
                font-size: 1em;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: -13px;
                    height: calc(100% + 26px);
                    width: 1px;
                    background: $color-gray15;
                    z-index: 1;
                }
            }

            &__suffix {
                flex-shrink: 1;
                margin-left: 3px;
                font-size: 1em;
            }
        }
    }

    &--focus, &--filled {
        &:before {
            bottom: calc(100% - 1em);
            font-size: 12px;
        }

        &:after {
            width: 100%;
        }
    }

    &--filled {
        &:after {
            background-color: $color-gray2;
        }
    }

    &--error {
        border-color: $color-red1 !important;

        // &:before {
        //     color: $color-red1;
        // }

        &:after {
            background-color: $color-red1;
        }
    }

    &--disabled {
        opacity: .4;
        pointer-events: none;

        &[data-disabled-like-text] {
            opacity: 1;
            border-bottom: none;

            &:after {
                display: none;
            }

            input {
                margin-bottom: 0;
            }
        }
    }

    &--type2 {
        border-radius: 4px;
        background-color: $color-white1;
        border-bottom: none;
        min-height: 60px !important;
        display: flex;
        padding: 13px !important;
        align-items: center;
        font-size: 27px;

        #{$input} {
            &__prefix, &__suffix {
                color: $color-gray4;
                font-weight: 900;
                opacity: .5;
            }
        }


         input {
                margin-bottom: 0 !important;
                font-size: .6em;

                &::placeholder {
                    color: $color-gray2;
                }
            }

         &:before, &:after {
            display: none;
        }
    
    }

    &--plain {
        border-bottom: none;
        margin: none;
        padding: 0;

         &:after {
            display: none;
        }

       input {
            margin: 0 !important;
            padding: 0;
        }       
    }
}

.#{$ui}-input-boxes {
    display: flex;

    &__box {
        width: 70px;
        height: 70px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

        &:not(:last-of-type) {
            margin-right: 16px;
        }

        input[data-input-box="true"] {
            background-color: transparent;
            width: 100%;
            height: 100%;
            text-align: center;
            border: none;
            font-size: 40px;
            font-weight: 900;
            color: $color-gray4;

            &:focus {
                outline: none;
            }
        }

        @include media-breakpoint-down(md) {
            width: 50px;
            height: 50px;

            input[data-input-box="true"] {
                font-size: 30px;
            }
        }

        @include media-breakpoint-down(xs) {
            width: 40px;
            height: 40px;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
#only-number::-webkit-outer-spin-button,
#only-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 800px) {
    .ant-input, .ant-select, .phone-input{
     font-size: 16px !important;
    }
  }