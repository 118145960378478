.#{$ui}-box {
    padding: 20px 30px;
    position: relative;
    $box: &;

    &--fluid {
        width: 100%;
    }

    &--transparent {
        background-color: transparent;
    }

    &--mh-100 {
        min-height: 100%;
    }

    &--white {
        background-color: #fff;
    }

    &--transparency-gray {
        background-color: #dfdfdf;
    }

    &--white2 {
        background-color: $color-white2;
    }

    &--gray {
        background-color: $color-gray4;
    }

    &--border-radius {
        border-radius: 4px;
    }

    &--border-radius-top {
        border-radius: 4px 4px 0 0;
    }
    
    &--border-radius-bottom {
        border-radius: 0 0 4px 4px;
    }

    &--border {
        border: 1px solid $color-gray2;
        border-radius: 4px;
    }

    &--list-item {
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    &--neg-margin-30 {
        width: calc(100% + 60px);
        margin-left: -30px;
    }

    &--sticky {
        position: sticky;
        z-index: 1010;
        top: 135px;

        @include media-breakpoint-down(md) {
            top: 55px;
        }
    }

    &--sticky-active {
        box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.15);
    }

    &--o-hidden {
        overflow: hidden;
    }

    &--o-x-hidden {
        overflow-x: hidden;
    }

    &--shadow {
        box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
        border-radius: 4px;
    }

    &--centered-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--top-radius-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &--no-radius {
        border-radius: 0;
    }

    &--shadow-black {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.33);
        border-radius: 4px;
    }

    &--shadow-black2 {
        box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
        border-radius: 4px;
    }

    &--padding-0 {
        padding: 0;
    }

    &--padding-t20-10 {
        padding: 20px 10px 10px 10px;
    }

    &--padding-10-20 {
        padding: 10px 20px;
    }

    &--padding-15 {
        padding: 15px;
    }

    &--padding-20 {
        padding: 20px;
    }

    &--padding-45 {
        padding: 45px 45px 30px 45px;
    }

    &--padding-30 {
        padding: 30px;
    }

    &--height-100p {
        height: 100%;
    }
    &--height-75 {
        height: 75px;
    }

    &--padding-sm-15 {
        @include media-breakpoint-down(sm) {
            padding: 15px;

            .#{$ui}-accordion {
                &__head {
                    &:after {
                        left: -7.5px;
                        width: calc(100% + 15px);
                    }
                }
            }
        }
    }

    &--flex-sections {
        display: flex !important;
        flex-direction: column;
    }

    &--hover {
         &:hover {
            box-shadow: 0 0 25px 0 rgba(111, 111, 111, 0.4);

            #{$box} {
                &__link {
                    box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 1);
                    display: flex;
                    animation-name: boxLinkReveal;
                    animation-duration: .3s;
                }
            }
        }
    }

    &__link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: none;
        background: transparent;
        align-items: center;
        justify-content: center;

        &--transparent {
            background: transparent;
            display: block;
        }

        @keyframes boxLinkReveal {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__header {
        &__image {
            height: 175px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__body, &__footer {
        padding: 15px;
    }

    &__footer {
        margin-top: auto;
    }
}