.#{$ui}-modal {

    background-color: rgba(0,0,0,.9);
    overflow: auto;

    .modal-title {
        width: 100%;
    }

    .modal-dialog {
        .modal-content {
            border: none;
            border-radius: 4px;

            &.modal-transparent {
                background-color: transparent; 
            }

            &.modal-content-vh {
                height: 80vh;
                min-height: auto;
                overflow-y: auto;
            }

            &.modal-no-body-padding {
               .modal-body {
                    padding: 0;
               }
            }

            .modal-body {
                padding: 45px;
            }

            .modal-header {
                padding: 20px 45px 0px 45px;
                border: none;
                display: flex;
                flex-direction: column;

                &.modal-header-px-30 {
                    padding: 30px 45px;
                }

                &.modal-header-bg-white2 {
                    background-color: $color-white2;
                }
                
                &.modal-header-box-shadow {
                    box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
                }
            }

            .modal-footer {
                &.modal-footer-sticky {
                    position: sticky;
                    bottom: 0;
                    padding: 30px 45px;
                    background-color: #fff;
                    border: none;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
                }
            }

            @include media-breakpoint-down(sm) {

                .modal-header {
                    padding: 20px 20px 0 20px;

                    &.modal-header-px-30 {
                        padding: 20px;
                    }
                }

                .modal-body {
                    padding: 20px;
                }
            }
         }
    }
}

.modal-close-button {
    position: fixed;
    right: 40px;
    top: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;


    i {
        font-size: 25px;
        margin-bottom: 5px;
        color: #fff;
    }

    @include media-breakpoint-down(sm) {
        right: 3px;
        top: 3px;
        background-color: $color-black1;
        padding: 5px;
        border-radius: 4px;

        i {
            margin-bottom: 0;
        }

        > div {
            display: none;
        }
    }
}

.modal-backdrop {
    background-color: #4d4e4e;

    &.show {
        opacity: .95;
    }
}


// Sweet Alert Modifications
.#{$ui}-swal {
    .swal2-popup {
        width: 100%;
        max-width: 800px;

        @include media-breakpoint-down(md) {
            max-width: 70vw;
        }

        @include media-breakpoint-down(md) {
            max-width: 95vw;
        }
    }

    .swal2-icon {
        &.swal2-error, &.swal2-warning, &.swal2-info, &.swal2-success {
            border-color: transparent;
        }
    }

    &.swal2-backdrop-show {
        background-color: rgba(77, 78, 78, .95);
    }
}