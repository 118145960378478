/*
 *
 * LIBS
 *
 */
// Bootstrap Overrides
$grid-columns: 15;
$grid-gutter-width: 20px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

$modal-dialog-margin: $grid-gutter-width / 2;
  
// Font Awesome 
@import '~@fortawesome/fontawesome-pro/css/all.min.css';

 // Bootstrap
 @import '~bootstrap/scss/bootstrap.scss';
/*  @import '~bootstrap/scss/bootstrap-grid';
 @import '~bootstrap/scss/mixins';
 @import '~bootstrap/scss/dropdown';
 @import '~bootstrap/scss/_breadcrumb'; */

// Slick Slider
 @import '~slick-carousel/slick/slick.css';
 @import '~slick-carousel/slick/slick-theme.css';

 // Ant Design
@import  '~antd/dist/antd.min.css';

 // Animate.css
 @import '~animate.css/animate.min.css';

