.#{$ui}-notification {
    position: relative;
    width: 100%;
    color: #fff;
    z-index: 1020;
    background-color: $color-gray4;
    display: none;
    $notification: &;

    &--fixed-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
    }

    &--info {
        background-color: $color-black1;
        color: $color-yellow2;

        #{$notification} {
            &__close {
                i {
                    color: $color-yellow2 !important;
                    opacity: 1;
                }
            }
        }
    }

    @keyframes notificationActive {
        0% { opacity:  0; bottom: 0;}
        100% { opacity:  1; bottom: 0; }
    }

    &--active {
        display: block;
        animation-name: notificationActive;
        animation-duration: 1s;
        animation-timing-function: cubic-bezier();
    }

    p {
        font-weight: 400;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    button {
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    
    &__inner {
        padding: 27px 45px;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(sm) {
            padding: 27px;
        }
    }

    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $color-black3;
        width: 100px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        
        .#{$ui}-button {
            font-size: 28px;
            
            i {
                color: $color-gray3;
                font-size: 28px;
                transition: all .3s;
            }
        }
        
        &:hover {
            .#{$ui}-button {
                
                i {
                    color: #FFF;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            border-left: none;
            right: 10px;
            top: 10px;
            width: auto;
            height: auto;
        }
    }
}