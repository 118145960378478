.#{$ui}-circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &--outline-yellow {
        border: 3px solid $color-yellow2;
        color: $color-gray3;
    }
}