.#{$ui}-video-with-thumbnail {
    position: relative;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
        padding-bottom: 100px;
    }

    &__video {
        width: 100%;
        max-height: 100%;
    }

    &__thumbnail-overlay-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__thumbnail-overlay {
        border-radius: 99999px;
        background-color: $color-yellow2;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__thumbnail-overlay-icon {
        color: white;
    }
}
