@use "sass:map";

.homepage-slider-image {
    opacity: .6;
}

.homepage-counter-container{
    position: relative;
    width: 100%;
    padding: 10px;
    height: 150px;
    border-radius: 4px;
    border: 1px solid $color-gray2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: all .3s;
    
    .homepage-counter-head-image{
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        background-color: white;
        position: absolute;
        top:-22.5px;
        z-index: 20;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img{
            width: 25px;
        }
        .homepage-counter-icon-hover{
            display: none;
        } 
    }

        .homepage-counter-title {
            font-size: 40px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            color: #4d4e4e;
        }
      
        .homepage-counter-description{
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            text-align: center;
            color:#848689;
        }

        &:hover {
            background-color: #fff;
            box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.66);
            border-color: transparent;
        
            .homepage-counter-title{
                color: #ffcc05
            }

            .homepage-counter-head-image{
                background-color: #ffcc05;
            }

            .homepage-counter-icon{
                display: none;
            }
            .homepage-counter-icon-hover{
                display: flex;
            }
        }
}

.footer-supports-line {
    width: 1px;
    height: 25px;
    object-fit: contain;
    background-color: rgba(216, 216, 216, 0.12);
}

.footer-form-checkbox{
    .ant-checkbox .ant-checkbox-inner {
        background-color: transparent;
        border-radius: 2px;
        border:2px solid #ffcc05;
        width: 18px;
        height: 18px;
    }
    
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $color-yellow2;
        border-color: $color-yellow2;
    }
    
    .ant-checkbox-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

}

.customers-says-slider-arrow{
    cursor: pointer;
    -webkit-filter: grayscale(1) !important;
    opacity: .5;
    filter: grayscale(1); 
     
        &:hover {
            -webkit-filter: grayscale(0) !important;
            opacity: 1;
            filter: none;
        }
}


.video-modal-main-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.7);

    .video-modal-video-container {
        width: 80%;
        height: 80%;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.auction-history-container{
    display: flex;
    flex-direction: column;
    max-height: 340px;

    .history-container{
        overflow-y: auto;
        flex-direction: column;
    }

    .auction-history-title{
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;

        span {
            flex: 1;
            margin: 17px 0;
            font-size: 16px;
            font-weight: 900;
            font-style: normal;
            text-align: center;
            color: #252525;
        }

        .offer-title {
            margin-left: 18%;
        }
    }

    .history {
        padding: 10px;
        display: flex;
        flex-direction: row;

        span {
            font-size: 13px;
            font-style: normal;
            text-align: center;
            color: #252525;
        }

        .user-side {
            width: 26%;
            text-align: left;
        }
    }
}

.machine-owner-container{
    border-radius: 5px;
    border: solid 1px #eeeeee;
    background-color: #f7f6f6;
    display: flex;
    flex-direction: column;
    flex:1;

    .machine-owner-info-container{
        display: flex;
        flex-direction: row;
        padding: 15px;
        border-bottom: solid 1px #eeeeee;

        .machine-owner-photo{
            width: 68px;
            height: 68px;
            border-radius: 34px;
        }
    }

    .machine-owner-info{
        display: flex;
        flex-direction: column;
        flex:1;
        align-items: flex-start;
        justify-content: center;
        margin-left: 15px;    
    
        .owner-fullname{
            font-size: 18px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4d4e4e;
            margin:0 !important
        }
        
        .owner-membership-type{
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #6f6f6f;
            margin:8px 0 0 0 !important
        }
    }

    .machine-owner-contact-main{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 15px 29px !important;
    
        .machine-owner-contact-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        
            .owner-contact{
                margin: 0 0 0 10px !important;
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #6f6f6f;
            }
        }
    }
}

.message-of-ceo-header-tag{
    position: absolute;
    width: 200px;
    height: 157px;
    top: -25%
}

.ceo-name-text {
    margin-top: 8px;
    font-weight: normal;
    color: #848689;
    font-style: italic;
}

.before-bidding-container {
    .content-header {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 12px;
        color: #4d4e4e;
    }

    i {
        color: #252525;
    }

    .line {
        width: 5px;
        margin: 0 25px 0 15px;
        border-radius: 6px;
        background-color: #ffcc05;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.brand-card {
    background-color: #ebebeb;
    border-radius: 4px;
    border: solid 1px #cccccc;
    padding: 15px 15px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .brand-card-title {
        width: 100%;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        color: #4d4e4e;
    }

    .brand-card-description {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #4d4e4e;
        margin-top: 10px;
    }

    &:hover {
        box-shadow: 0 0 40px 0 rgba(95, 95, 95, 0.66);
    }
}

.brand-detail {
    .brand-detail-brand-image {
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 rgba(175, 175, 175, 0.4);
        background-color: white;
    }

    .section-line {
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
    }

    .model-container {
        width: 100%;
        padding: 10px;
        height: 150px;
        border-radius: 4px;
        border: 1px solid $color-gray2;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        transition: all .3s;

        .model-title {
            font-size: 16px;
            font-weight: 900;
            color: #4d4e4e;
        }

        .model-description {
            font-size: 14px;
            font-weight: normal;
            color: #848689;
        }

        &:hover {
            background-color: #fff;
            box-shadow: 0 0 40px 0 rgba(207, 207, 207, 0.66);
            border-color: #ffcc05;
        }
    }
}

.status-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 15px;
        color: #fff;
    }

    &--success {
        background-color: $color-green2;
    }

    &--error {
        background-color: $color-red1;
    }
}

.product-card-image, .im-box__header {
    position: relative;

    .image-sold-text-container {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .34);

        .image-sold-text {
            color: #252525;
            font-size: 40px;
            font-weight: 900;
            background-color: #ffcc05;
            padding: 10px 20px;
            -ms-transform: rotate(-25deg);
            transform: rotate(-25deg);
            border-radius: 10px;
            white-space: nowrap;
        }
    }
}

.blog-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;

    .blog-card-image {
        position: relative;
        min-height: 240px;
    }

    .blog-card-text-area {
        display: flex;
        flex-direction: column;
        padding: 30px;

        strong {
            font-weight: 900;
        }

        .blog-card-date {
            font-size: 12px;
            font-weight: 500;
            color: #848689
        }

        .blog-card-title {
            font-size: 22px;
            font-weight: 900;
            line-height: 1.2;
            color: #252525;
            margin-top: 1.5rem;
        }

        .blog-card-description {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.3;
            color: #6f6f6f;
            margin-top: 1.5rem;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        box-shadow: 0 0 40px 0 rgba(95, 95, 95, 0.66);
    }
}

.blog-card-others {
    display: flex;
    width: 100%;
    background-color: white;
    flex-direction: column;

    .blog-card-image {
        position: relative;
        min-height: 240px;
    }

    .blog-card-text-area {
        display: flex;
        flex-direction: column;
        padding: 30px;

        strong {
            font-weight: 900;
        }

        .blog-card-date {
            font-size: 12px;
            font-weight: 500;
            color: #848689
        }

        .blog-card-title {
            font-size: 20px;
            font-weight: 900;
            line-height: 1.2;
            color: #252525;
            margin-top: 1.5rem;
        }

        .blog-card-description {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.3;
            color: #6f6f6f;
            margin-top: 1.5rem;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        box-shadow: 0 0 40px 0 rgba(95, 95, 95, 0.66);
    }
}

.blog-detail-title {
    font-size: 40px;
    color: #252525;
    font-weight: 900;
    margin-bottom: 0;
}

.blog-content > .se-image-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.blog-content > .se-image-container > figure {
    width: auto !important;
}

.blog-content > .se-image-container img {
    max-width: 100%;
    height: auto !important;
}

.blog-detail-socials {
    display: none;
    flex-direction: row;
    justify-content: center;
}

.blog-detail-main-date {
    font-size: 12px;
    font-weight: 500;
    color: #848689
}

.blog-socials-container {
    width: 130px;
    height: 280px;
    padding: 35px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);

    a {
        margin: 10px;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: #848689;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
            color: white;
        }

        &:hover {
            background-color: #252525;

            i {
                color: #ffcc05;
            }
        }
       
    }
}

@media (min-width: map.get($grid-breakpoints, "md")) {
    .blog-card--large {
        width: 100%;

        .blog-card-image {
            position: relative;
            min-height: 360px;
            width: 100%;
        }

        .blog-card-text-area {
            .blog-card-date {
                font-size: 16px;
            }
        
            .blog-card-title {
                font-size: 35px;
            }
    
            .blog-card-description {
                font-size: 23px;
                font-weight: 500;
                -webkit-line-clamp: 3;
            }
        }
    }

    .blog-detail-socials {
        display: flex;
    }
}

@media (min-width: map.get($grid-breakpoints, "lg")) {
    .blog-card--large {
        flex-direction: row;

        .blog-card-image {
            width: 50%;
        }

        .blog-card-text-area {
            flex: 1;

            .blog-card-description {
                -webkit-line-clamp: 5;
            }
        }
    }

    .blog-card-others {
        flex-direction: row;

        .blog-card-image {
            width: 45%;
        }

        .blog-card-text-area {
            flex: 1;

            .blog-card-description {
                -webkit-line-clamp: 3;
            }
        }
    }
}

@media (min-width: map.get($grid-breakpoints, "xl")) {
    .blog-card {
        .blog-card-image {
            min-height: 300px;
        }
    }

    .blog-card--large {
        .blog-card-image {
            min-height: 420px;
            width: 60%;
        }

        .blog-card-text-area {
            .blog-card-description {
                -webkit-line-clamp: 7;
            }
        }
    }

    .blog-card-others {
        .blog-card-image {
            min-height: 300px;
            width: 50%;
        }

        .blog-card-text-area {
            .blog-card-description {
                -webkit-line-clamp: 6;
            }
        }
    }
}
