.ant-form{
    font-family: Roboto, sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #4d4e4e;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.ant-form-item-label label{
    color: #848689;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
}