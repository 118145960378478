    a.#{$ui}-button, button.#{$ui}-button, .#{$ui}-button {
        text-decoration: none;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        padding: 0 30px;
        font-size: 15px;
        line-height: 1;
        border-radius: 4px;
        font-weight: 900;
        border: 2px solid transparent;
        transition: .4s background-color;
        outline: none !important;
        color: $color-gray4;
        $button: &;

        i {
            font-size: 1.5em;
        }

        &__icon--right, &__icon--left {
            position: relative;
            transition: all .3s;
        }

        &__icon--right {
            left: 0;
        }

        &:not(:disabled):not(#{$button}--disabled) {
            cursor: pointer;
        }
    
        &--primary {
            background-color: $color-yellow2;
            color: $color-gray4;

            &:hover, &:focus, &.active {
                // background-color: darken($color-yellow2, 5%);
                background-color: $color-yellow3;
                color: $color-gray4;

                #{$button} {
                    &__icon--right {
                        left: 5px;
                    }
                }
                    
            }
        }

        &--mw-110 {
            min-width: 110px;
        }

        &--mw-110 {
            min-width: 110px;
        }

        &--mw-245 {
            min-width: 245px;
        }

        &--white {
            background-color: #fff;

            &:hover, &:focus, &.active {
                background-color: darken(#fff, 15%);
            }
        }

        &--white2 {
            background-color: $color-white2;

            &:hover, &:focus, &.active {
                background-color: darken($color-white2, 15%);
            }
        }

        &--green2 {
            background-color: $color-green2;

            color: #fff;

            &:hover, &:focus, &.active {
                background-color: lighten($color-green2, 5%);
            }
        }

        &--gray4 {
            background-color: $color-gray4;
            color: #fff;

             &:hover, &:focus, &.active {
                background-color: darken($color-gray4, 15%);
            }
        }

        &--no-gutter {
            padding: 0;
            border: none;
        }

        &--less-gutter {
            padding: 0 15px;
        }

        &--gutter-25 {
            padding: 0 25px;
        }

        &--gutter-20 {
            padding: 0 20px;
        }

        &--gutter-10 {
            padding: 0 10px;
        }

        &--gutter-5 {
            padding: 0 5px;
        }

        &--outline-less-tickness {
            border-width: 1px;
            font-weight: 500;
        }

        &--outline-white {
            background-color: transparent;
            color: $color-white3;
            border-color: $color-white3;

            &:hover, &:focus, &.active {
                background-color: $color-white3;
                color: $color-gray4;
            }
        }

        &--outline-yellow2 {
            background-color: transparent;
            color: $color-gray4;
            border-color: $color-yellow2;

            &:hover, &:focus, &.active {
                background-color: $color-yellow2;
                color: $color-gray4;
            }
        }

        &--outline-yellow2-t2 {
            background-color: transparent;
            color: $color-yellow2;
            border-color: $color-yellow2;

            &:hover, &:focus, &.active {
                background-color: $color-yellow2;
                color: $color-gray4;
            }
        }

        &--outline-gray {
            background-color: transparent;
            color: $color-gray3;
            border-color: $color-gray3;

            &:hover, &:focus, &.active {
                background-color: $color-gray3;
                color: $color-white1;
            }
        }
        
    
        &--gray-outline-yellow {
            background-color: $color-white2;
            color: $color-gray10;
            border-color: $color-white1;
            border-width: 1px;
            
            &:hover, &:focus, &.active {
                border-color: $color-yellow2;
                color: $color-gray12;
            }
        }

        &--outline-gray-yellow-t2 {
            background-color: transparent;
            color: $color-gray4;
            border-color: $color-gray3;
            font-weight: 400;

            &:hover, &:focus, &.active {
                background-color: $color-yellow2;
                color: $color-gray12;
                border-color: $color-yellow2;
                font-weight: 500;
            }
        }

        &--outline-gray12 {
            background-color: transparent;
            color: $color-gray12;
            border-color: $color-gray12;

            &:hover, &:focus, &.active {
                background-color: $color-gray12;
                color: $color-yellow2;
            }
        }

        &--outline-black {
            background-color: transparent;
            color: $color-black1;
            border-color: $color-black1;

            &:hover, &:focus, &.active {
                background-color: $color-black1;
                color: $color-white1;
            }
        }

        &--transparent-white {
            background-color: transparent;
            color: #fff;

            &:hover, &:focus, &.active {
                background-color: transparent;
                color: #fff;
            }
        }

        &--transparent-yellow2 {
            background-color: transparent;
            color: $color-yellow2;

            span {
                color: inherit;
            }

            &:hover, &:focus, &.active {
                background-color: transparent;
                color: $color-yellow2;
            }
        }

        &--blank {
            background-color: transparent;
            padding: 0;
            min-height: auto;
        }

        &--text-500 {
            font-weight: 500;
        }

        &--transparent {
            background-color: transparent;

            &:hover, &:focus, &.active {
                background-color: transparent;
            }
        }

        &--auto-height {
            min-height: auto;
        }

        &--size-small {
            min-height: 44px;
        }

        &--size-30 {
            min-height: 30px;
        }

        &--size-35 {
            min-height: 35px;
        }

        &--size-40 {
            min-height: 40px;
        }

        &--size-60 {
            min-height: 60px;
        }

        &--size-85 {
            min-height: 85px;
        }

        &--fluid {
            width: 100%;
        }

        &--fluid-height {
            height: 100%;
        }

        &--center {
            justify-content: center;
        }

        &--size-wide {
            padding-left: 45px;
            padding-right: 45px;
        }

        &--disabled {
            opacity: .7;
            cursor: not-allowed;
            pointer-events: none;
        }

        &--mobile-center {
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }

        &--mobile-fluid {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &__icon {
            transition: all .4s;
            position: relative;

            &--left {
                margin-right: 10px;
            }

            &--right {
                margin-left: 10px;
            }

            &--left-wider-gap {
                margin-right: 20px;
            }

            &--right-wider-gap {
                margin-left: 20px;
            }
        }
    }



.#{$ui}-button-group {
    display: flex;

    .im-button {
        &:not(:last-child):not(:first-child) {
            border-radius: 0;
            border-right: transparent;
        }

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: transparent;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:hover, &:focus, &.active {
            & + .im-button {
                border-left-color: transparent !important;
            }
        }
    }
}