.#{$ui}-textarea {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    @extend .#{$ui}-form-component;
    position: relative;
    font-size: 16px;
    border-bottom: 1px solid $color-gray2;
    padding-top: 14px;

    textarea {
        border: none;
        width: 100%;
        margin-bottom: 13px;
        position: relative;
        line-height: 1.4;
        font-size: inherit;
        font-weight: 700;
        color: $color-gray11;
        background-color: transparent;
        height: 110px;
        resize: none;

        &:focus {
            outline: none;
        }
    }

     &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: $color-gray2;
        transition: all .2s;
    }
    
    &:before {
        content: attr(data-placeholder);
        display: block;
        position: absolute;
        bottom: calc(100% - 2em);
        left: 0;
        transition: all .3s;
        z-index: 1;
        pointer-events: none;
        font-size: inherit;
        color: $color-gray3;
        font-weight: 400;
    }

    &--auto-height {
        textarea {
            height: auto;
        }
    }

    &--focus {
        &:before {
            bottom: calc(100% - 1em);
            font-size: 12px;
        }

        &:after {
            width: 100%;
        }
    }

    &--error {
        border-color: $color-red1 !important;

        // &:before {
        //     color: $color-red1;
        // }

        &:after {
            background-color: $color-red1;
        }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }

    &:not([data-placeholder]) {
        padding-top: 0;
        margin-bottom: 0;

        textarea {
            margin-bottom: 0;
        }
    }

    &--plain {
        border-bottom: none;
        margin: none;
        padding: 0;

         &:after {
            display: none;
        }

       textarea {
            margin: 0 !important;
            padding: 0;
        }       
    }
}